.resize-wrapper {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: #000;
    opacity: 0.9;
    z-index: 1000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.resize-wrapper img{
     width: 300px; 
     position: absolute
}